<template>
<p>กำลังนำท่านสู่หน้าล็อคอิน...</p>
</template>

<script>
const configAuthen = require(`../../config`).auth
export default {
  name: 'Signin',
  data(){
    return {
    }
  },
  created() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('profile')
    localStorage.removeItem('cmuMobileToken')
    let url = `https://login.microsoftonline.com/${configAuthen['tenantId']}/oauth2/v2.0/authorize`
    let _queryOne = `client_id=${configAuthen['clientId']}&response_type=code&redirect_uri=${encodeURI(configAuthen['redirectUrl'])}`
    let _queryTwo = `response_mode=query&scope=offline_access%20user.read&state=12345`
    window.location.replace(`${url}?${_queryOne}&${_queryTwo}`)
  
  },
}
</script>
